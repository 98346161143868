<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <paginated-table
            create-new-item-label="Create New Product Sub Category"
            :headers="headers"
            :items="items"
            :pagination-details="paginationDetails"
            @createNewItem="createNewItem"
            @editItem="editItem"
            @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
            @fetchPreviousPage="
              fetchDataByUrl(this.paginationDetails.previousPageUrl)
            "
            @updateItemsPerPage="updateItemsPerPage"
            @updateItemStatus="updateItemStatus"
            @updateSelectedPage="updateSelectedPage"
          ></paginated-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import PaginatedTable from "@/components/Tables/PaginatedTable.vue";
export default {
  name: "product-sub-categories",
  components: {
    HeaderTopDashboard,
    PaginatedTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "# Groups", value: "numGroups" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    createNewItem() {
      this.$router.push("/product-sub-categories/create");
    },

    editItem(item) {
      this.$router.push({
        name: "Edit Product Sub Category",
        params: {
          id: `${item.id}`,
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      this.paginationDetails.loading = true;
      this.$http
        .get(url, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            const productCategory = result.data.data[i];

            this.items.push({
              id: productCategory.id,
              productCategoryImage: `${this.sessionStore.getApiHost}${productCategory.image_uri}`,
              name: productCategory.name,
              status: productCategory.status,
              numGroups: productCategory.groups_count,
            });
          }

          this.setPaginationDetails(result.data);
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while loading the data. Please try again or contact support if the issue persists."
          );
        })
        .finally(() => {
          this.paginationDetails.loading = false;
        });
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },

    updateItemStatus(item, status) {
      const body = {
        status,
      };

      this.$http
        .patch(
          `/product-sub-categories/${item.id}`,
          body,
          this.sessionStore.getHttpConfig
        )
        .then(() => {
          this.fetchDataByUrl("/product-sub-categories");
          this.$notify.success("Status updated successfully.");
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while updating the status."
          );
        });
    },
  },
  mounted() {
    this.fetchDataByUrl("/product-sub-categories");
  },
};
</script>
